import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { DicomFooter, HealthcareHero } from "../assets/backgrounds";
import NewHomeLeadFormWithAPIForm from "../components/NewHomeLeadFormWithAPI";
import VideoComponent from "../components/VideoComponent";
import BetterModels from "../components/rlhf_Page/BetterModels";
import SideCard from "../components/rlhf_Page/SideCard";
import Layout from "../components/layout";
import RowsSection from "../components/rlhf_Page/RowsSection";
import SEO from "../components/seo";
import { pickSlice } from "../utilities/helpers";
import HeroPosterRlhf from "../assets/rlhf-hero-poster.png";
import HeroPosterRsna from "../assets/rsna-hero-video-poster.png";
import CustomLink from "../components/CustomLink";
import "../styles/page/dicom_page.scss";
import "../styles/page/rlhf_page.scss";
import LogoMarquee from "../components/LogoMarquee";
import { headerTheme } from "../partials/Header";
import { Toaster } from "react-hot-toast";

const Rlhf = ({ location, data }) => {
  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const trusted_brands_list =
    data?.allPrismicTrustedBrandsLogos &&
    !!data.allPrismicTrustedBrandsLogos?.nodes.length &&
    data.allPrismicTrustedBrandsLogos?.nodes[0]?.data?.body[0];

  const _data = data?.allPrismicRlhf?.nodes[0]?.data || {};
  const [repeatable_cards] = pickSlice(_data, "repeatable_cards");
  const page_section = pickSlice(_data, "page_section");
  const first_page_section = page_section[0];
  const second_page_section = page_section[1];
  const [better_model] = pickSlice(_data, "better_model");

  return (
    <Layout location={location} theme="purple" hideHeader={true}>
      <main className="overflow-hidden rlhf_bg" id="book-demo">
        <div className="relative">
          <div className="absolute z-0 w-full -translate-y-8 h-80 sm:top-24 top-28 sm:translate-y-0 sm:left-0 -left-20">
            <HealthcareHero />
          </div>
          <header
            className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
              !top &&
              `backdrop-blur shadow-lg ${headerTheme["purple"].background}`
            }`}
          >
            <div className="px-5 mx-auto bg-transparent max-w-7xl sm:px-6">
              <div className="flex items-center justify-between py-4 lg:py-7">
                {/* Site branding */}
                <div className="flex-shrink-0 mr-4">
                  {/* Logo */}
                  <CustomLink to="/" className="block">
                    {headerTheme["purple"].logo}
                  </CustomLink>
                </div>
              </div>
            </div>
          </header>
          <article className="px-5 pt-16 mx-auto max-w-7xl md:pt-28 xl:px-0 sm:px-6">
            <section className="relative">
              <div className="absolute z-0 hidden rlhf_hero_elipse lg:top-128 top-3/4 lg:right-5 right-1 sm:block"></div>
              <section className="relative flex flex-col max-w-3xl mx-auto mb-6 lg:mb-8 sm:mb-14 sm:gap-y-4 gap-y-6 lg:mt-0 mt-14 sm:px-0">
                <div
                  dangerouslySetInnerHTML={{ __html: _data?.page_heading.html }}
                  className="h1_gradient_dicom text-3xl lg:leading-13 tracking-tighter_3 sm:text-4.5xl font-semibold text-center lg:px-4"
                />

                <p className="max-w-xl px-10 mx-auto text-base text-center tracking-tighter_1 sm:text-xl sm:px-0">
                  {_data?.page_description?.text}
                </p>
                <section className="flex justify-center">
                  <NewHomeLeadFormWithAPIForm
                    location={location}
                    btnText="Book demo"
                    routerId="768"
                    isCustomBtnBg={true}
                    btnColor="dicom-btn border-0 px-5 h-[50px]"
                    allowAllEmails
                    formId={
                      location?.pathname.includes("rsna-2023")
                        ? "7fb20b16-8447-4403-abc0-41a2e42965b4"
                        : "9d96ba5b-2fce-4492-8d9d-34478ab8fd25"
                    }
                  />
                </section>
              </section>

              <section className="relative max-w-5xl mx-auto rounded-lg lg:rounded-3xl sm:rounded-xl">
                {_data?.hero_section_video?.url ? (
                  <VideoComponent
                    poster={
                      location?.pathname.includes("rsna-2023")
                        ? HeroPosterRsna
                        : HeroPosterRlhf
                    }
                    loop
                    autoPlay
                    muted
                    className="mx-auto h-auto max-h-[500px] lg:rounded-3xl sm:rounded-xl rounded-lg"
                  >
                    <source
                      src={_data?.hero_section_video?.url}
                      type="video/mp4"
                    />
                  </VideoComponent>
                ) : (
                  <>
                    {_data?.hero_section_image?.url ? (
                      <img
                        src={_data?.hero_section_image?.url}
                        loading="eager"
                        width={0}
                        height={0}
                        alt="stats"
                        className="max-w-4xl mx-auto w-full max-h-[600px] lg:rounded-3xl sm:rounded-xl rounded-lg"
                      />
                    ) : (
                      <section className="mt-20 2xl:mt-28 w-full h-[512px] rounded-xl shadow-card" />
                    )}
                  </>
                )}
              </section>
              {trusted_brands_list && (
                <section className="mx-auto space-y-4 sm:py-12 py-9">
                  <section className="img_grayscale_opacity">
                    <p className="text-center uppercase lg:text-xl text-base text-white tracking-[0.07em]">
                      {trusted_brands_list?.primary?.label?.text}
                    </p>
                  </section>
                  <LogoMarquee
                    logoArray={trusted_brands_list}
                    logoBackground="logo_bg"
                  />
                </section>
              )}
            </section>
          </article>

          {(repeatable_cards || first_page_section) && (
            <section className="relative">
              <div className="absolute hidden rlhf_cards_bg lg:top-1/3 top-72 -left-10 sm:block" />
              {repeatable_cards && (
                <section className="relative">
                  {repeatable_cards && (
                    <section className="relative">
                      <article className="px-5 mx-auto max-w-7xl xl:px-0 sm:px-6">
                        <div className="pt-4 pb-16 mx-auto sm:pb-14 lg:pt-8 lg:pb-20 lg:max-w-5xl">
                          <h2 className="text-center text-[#C5BBFF] uppercase -tracking-tightest font-medium mb-5 text-sm sm:text-base">
                            {repeatable_cards?.primary?.section_label?.text}
                          </h2>

                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                repeatable_cards?.primary?.section_heading
                                  ?.html,
                            }}
                            className="text-2.5xl sm:leading-12 tracking-tighter_3 sm:text-3.75xl font-semibold text-center lg:px-10 sm:px-3 mx-auto sm:max-w-2xl lg:max-w-3xl"
                          />
                          <section className="flex justify-center pt-5 mb-12 sm:mb-16">
                            <CustomLink
                              href={"#book-demo"}
                              className={`text-white btn dicom-btn`}
                            >
                              Book demo
                            </CustomLink>
                          </section>
                          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-y-6 gap-y-6 lg:gap-x-6 sm:gap-x-3">
                            {repeatable_cards?.items.map((item, index) => (
                              <SideCard
                                key={index}
                                data={item}
                                cardClass="side_card_bg"
                                headingClass="text-gray-1500 text-2xl"
                                descriptionClass="text-gray-1500"
                                imageClasses="rounded-lg"
                                containerClasses="side_card_padding"
                              />
                            ))}
                          </div>
                        </div>
                      </article>
                    </section>
                  )}
                </section>
              )}

              {first_page_section && (
                <section className="relative">
                  <div className="absolute z-0 hidden usecase_tab_elipse left-20 top-96 lg:hidden sm:block" />
                  <article className="relative px-3 mx-auto max-w-7xl xl:px-0 md:px-6 sm:px-0">
                    <h2 className="text-center text-[#C5BBFF] uppercase -tracking-tightest font-medium mb-5 text-sm sm:text-base">
                      {first_page_section?.primary?.section_label?.text}
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          first_page_section?.primary?.section_heading?.html,
                      }}
                      className="text-2.5xl sm:leading-12 tracking-tighter_3 sm:text-3.75xl !font-semibold text-center lg:px-20 sm:px-22 mx-auto sm:max-w-2xl"
                    />
                    <section className="flex justify-center pt-5 mb-16 lg:mb-20 sm:mb-14">
                      <CustomLink
                        href={"#book-demo"}
                        className={`text-white btn dicom-btn cursor-pointer`}
                      >
                        Book demo
                      </CustomLink>
                    </section>
                    <section className=" lg:mt-12 enhance_bg md:px-0 sm:px-6">
                      <RowsSection
                        imageContainer="image_Full_container"
                        isCustom="lg:!pr-0 lg:!py-0 section_description"
                        isColReverse={false}
                        isReverse={false}
                        heading={
                          first_page_section?.items[0]?.section_heading?.text
                        }
                        description={
                          first_page_section?.items[0]?.section_description
                            ?.text
                        }
                        imgSrc={
                          first_page_section?.items[0]?.section_image?.url
                        }
                      />
                    </section>

                    <section className=" lg:mt-12 enhance_bg md:px-0 sm:px-6">
                      <RowsSection
                        isColReverse={false}
                        isReverse={true}
                        heading={
                          second_page_section?.items[0]?.section_heading?.text
                        }
                        description={
                          second_page_section?.items[0]?.section_description
                            ?.text
                        }
                        imgSrc={
                          second_page_section?.items[0]?.section_image?.url
                        }
                      />
                    </section>
                  </article>
                </section>
              )}
            </section>
          )}

          {better_model && (
            <section className="relative">
              <div className="absolute hidden better_model_gradient lg:top-72 lg:-left-36 top-128 -left-16 sm:block" />
              <div className="absolute z-0 block lg:-bottom-28 sm:-bottom-20 -bottom-10 lg:left-auto -left-3/4">
                <DicomFooter />
              </div>

              <article className="relative px-5 mx-auto max-w-7xl xl:px-0 md:px-6 sm:px-0">
                <section className="xl:px-0 sm:px-6 rhlf_better_modal">
                  <BetterModels
                    heading={better_model?.primary?.section_heading?.text}
                    label={better_model?.primary?.section_label?.text}
                    ctaHeading={_data?.cta_heading?.text}
                    modelTypes={better_model?.items}
                    pageName="rlhf"
                    allowAllEmails
                    formId={
                      location?.pathname.includes("rsna-2023")
                        ? "7fb20b16-8447-4403-abc0-41a2e42965b4"
                        : "9d96ba5b-2fce-4492-8d9d-34478ab8fd25"
                    }
                  />
                </section>
              </article>
            </section>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default Rlhf;

export const query = graphql`
  query AllPrismicRlhf($uid: String!) {
    allPrismicRlhf(filter: { uid: { eq: $uid } }) {
      nodes {
        uid
        data {
          page_heading {
            html
            text
          }
          page_description {
            html
            text
          }
          meta_heading {
            html
            text
          }
          meta_description {
            html
            text
          }
          hero_section_video {
            url
          }
          cta_heading {
            html
            text
          }
          body {
            ... on PrismicRlhfDataBodyRepeatableCards {
              id
              slice_type
              items {
                section_description {
                  html
                  text
                }
                section_heading {
                  html
                  text
                }
                section_image {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
                section_video {
                  url
                }
              }
              primary {
                section_heading {
                  html
                  text
                }
                section_label {
                  html
                  text
                }
              }
            }
            ... on PrismicRlhfDataBodyPageSection {
              id
              slice_type
              items {
                section_description {
                  html
                  text
                }
                section_image {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
                section_heading {
                  html
                  text
                }
              }
              primary {
                section_heading {
                  html
                  text
                }
                section_label {
                  html
                  text
                }
              }
            }
            ... on PrismicRlhfDataBodyBetterModel {
              id
              slice_type
              items {
                section_feature_description {
                  html
                  text
                }
                section_feature_heading {
                  html
                  text
                }
                section_feature_icon {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
              }
              primary {
                section_heading {
                  html
                  text
                }
                section_label {
                  html
                  text
                }
              }
            }
          }
        }
      }
    }
    allPrismicTrustedBrandsLogos(filter: { uid: { eq: "rlhf-page-logos" } }) {
      nodes {
        data {
          body {
            ... on PrismicTrustedBrandsLogosDataBodyTrustedBrandsList {
              id
              primary {
                label {
                  text
                }
              }
              items {
                brand_image {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.allPrismicRlhf?.nodes[0]?.data || {};

  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};
